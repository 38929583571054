import React from 'react';

import classnames from 'classnames';

import useFullFreeshipping from './use-full-freeshipping';
import FullSnackbar from './full-shipping-info';

const eshopClassName = 'eshops-fullsnackbar';

const EshopsFreeshippingContainer = () => {
  const { currentFullFreeshipping } = useFullFreeshipping();

  if (!currentFullFreeshipping) {
    return null;
  }

  const { className } = currentFullFreeshipping;

  return <FullSnackbar className={classnames(eshopClassName, className)} />;
};

EshopsFreeshippingContainer.propTypes = {};

export default EshopsFreeshippingContainer;
